<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Potassium chlorate,
        <chemical-latex content="KClO3," />
        decomposes according to the reaction:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 KClO3(s) + heat -> KCl(s) + 3 O2(g)" />
      </p>

      <p class="mb-2">
        a) Determine the number of moles of
        <chemical-latex content="O2" />
        formed when
        <number-value :value="mass" unit="\text{g}" />
        of
        <chemical-latex content="KClO3" />
        decomposes.
      </p>

      <calculation-input
        v-model="inputs.molO2"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) What volume (in L) would the
        <chemical-latex content="O2" />
        gas, that was generated in the reaction, occupy at 1 atm and
        <number-value :value="temp" unit="^\circ\text{C?}" />
      </p>

      <calculation-input
        v-model="inputs.volO2"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question430',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        volO2: null,
        molO2: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    temp() {
      return this.taskState.getValueBySymbol('temp').content;
    },
  },
};
</script>
